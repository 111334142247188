/* eslint-disable react/prop-types */
import React from "react";
import { Button, CircularProgress, MenuItem, TextField } from "@mui/material";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import * as yup from "yup";
import { useLocation } from "react-router-dom";
import {
  fetchKebeles,
  fetchRegions,
  fetchWoredas,
  fetchZones,
  getDaProfile,
  registerDa,
  updateDa,
  getDaProfileFromDaRegistry,
  verifyBotUser,
  fetchRoles,
} from "../services/api_services";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { isEmpty } from "../utils/isEmpty";
import { parsePhoneNumber } from "../utils/parsePhoneNumber";

const RegisterDaForm = ({ isUpdate = false }) => {
  const { t, i18n } = useTranslation();

  const genderOptions = [
    {
      value: "male",
      label: t("male"),
    },
    {
      value: "female",
      label: t("female"),
    },
  ];

  // const roleOptions = [
  //   {
  //     value: "plant_science",
  //     label: t("plant_science"),
  //   },
  //   {
  //     value: "animal_Science",
  //     label: t("animal_Science"),
  //   },
  //   {
  //     value: "natural_resource_management",
  //     label: t("natural_resource_management"),
  //   },
  // ];

  const validationSchema = yup.object({
    first_name: yup.string().required(t("required")),
    father_name: yup.string().required(t("required")),
    grand_father_name: yup.string().required(t("required")),
    phone_number: yup
      .string()
      .matches(/^(09|07)\d{8}$/, t("phone_number_validation"))
      .required(t("required")),
    gender: yup.string().required(t("required")),
    telegram_phone_number: yup
      .string()
      .matches(/^(09|07)\d{8}$/, t("telegram_phone_number_validation"))
      .required(t("required")),
    region: yup.string().required(t("required")),
    zone: yup.string().required(t("required")),
    woreda: yup.string().required(t("required")),
    kebele: yup.string().required(t("required")),
    role: yup.string().required(t("required")),
  });
  const tele = window.Telegram.WebApp;
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const user_id = params.get("user_id");
  const phone_number = params.get("phone_number");
  const lang = params.get("lang");
  const update_outbound = params.get("update_outbound");
  const [isLoading, setIsLoading] = useState(true);
  const [initialValues, setInitialValues] = useState({
    first_name: "",
    father_name: "",
    grand_father_name: "",
    phone_number: "",
    telegram_phone_number: "",
    gender: "",
    region: "",
    zone: "",
    woreda: "",
    kebele: "",
    role: "",
  });
  const [isPopulatingProfile, setIsPopulatingProfile] = useState(isUpdate);
  const [regionOptions, setRegionOptions] = useState([]);
  const [roleOptions, setRoleOptions] = useState([]);
  const [zoneOptions, setZoneOptions] = useState([]);
  const [woredaOptions, setWoredaOptions] = useState([]);
  const [kebeleOptions, setKebeleOptions] = useState([]);
  const [error, setError] = useState("");
  const [formErrors, setFormErrors] = useState({});
  const [fetchedProfileData, setFetchedProfileData] = useState("");

  const fetchProfile = async () => {
    setIsPopulatingProfile(true);
    var data = {};
    const responseOutBound = await getDaProfile({
      phone_number: parsePhoneNumber(phone_number),
    });

    if (responseOutBound.status == 200) {
      if (!isEmpty(responseOutBound.data) && !isEmpty(responseOutBound.data.results)) {
        data = responseOutBound.data.results[0];
      } else {
        const responseDaRegsitry = await getDaProfileFromDaRegistry({
          phone_number: parsePhoneNumber(phone_number),
        });
        if (responseDaRegsitry.status == 200) {
          if (!isEmpty(responseOutBound.data)) {
            data = responseDaRegsitry.data;
          }
        }
      }
    }

    if (!isEmpty(data)) {
      setFetchedProfileData(data);
    }
    if (!isEmpty(data.region)) {
      fetchZones({
        selectedRegionId: data.region.id,
        setZoneOptions: setZoneOptions,
        setIsLoading: setIsLoading,
      });
    }

    if (!isEmpty(data.zone)) {
      fetchWoredas({
        selectedZoneId: data.zone.id,
        setWoredaOptions: setWoredaOptions,
        setIsLoading: setIsLoading,
      });
    }

    if (!isEmpty(data.woreda)) {
      fetchKebeles({
        selectedWoredaId: data.woreda.id,
        setKebeleOptions: setKebeleOptions,
        setIsLoading: setIsLoading,
      });
    }
    var kebele = "";
    var role = "";

    if (isUpdate) {
      if (typeof data.kebele === "object") {
        kebele = data.kebele.id;
      } else {
        kebele = data.kebele;
      }
    }

    if (!isEmpty(data.specialization)) {
      for (const option of roleOptions) {
        if (option.label.trim().toLowerCase() == data.specialization.trim().toLowerCase()) {
          role = option.value;
          break;
        }
      }
    } else if (!isEmpty(data.role)) {
      role = data.role;
    } else {
      role = "";
    }

    setInitialValues({
      first_name: !isEmpty(data.first_name) ? data.first_name : !isEmpty(data.name) ? data.name : "",
      father_name: !isEmpty(data.father_name) ? data.father_name : "",
      grand_father_name: !isEmpty(data.grand_father_name) ? data.grand_father_name : "",
      phone_number: !isEmpty(data.phone_number)
        ? data.phone_number.startsWith('9')
          ? `0${data.phone_number}`
          : data.phone_number
        : "",
      telegram_phone_number: !isEmpty(data.telegram_phone_number)
        ? data.telegram_phone_number.startsWith('9')
          ? `0${data.telegram_phone_number}`
          : data.telegram_phone_number
        : "",
      gender: !isEmpty(data.gender) ? data.gender.toLowerCase() : !isEmpty(data.sex) ? data.sex : "",
      region: !isEmpty(data.region) ? data.region.id : "",
      zone: !isEmpty(data.zone) ? data.zone.id : "",
      woreda: !isEmpty(data.woreda) ? data.woreda.id : "",
      kebele: kebele,
      role: role,
      created_by: user_id,
    });

    setIsPopulatingProfile(false);
  };
  useEffect(() => {
    if (isUpdate && !isEmpty(regionOptions) && !isEmpty(roleOptions)) {
      fetchProfile();
    }
  }, [isUpdate, regionOptions, roleOptions]);

  useEffect(() => {
    tele.expand();
    tele.MainButton.text = isUpdate ? t("update") : t("register");
    tele.MainButton.show();
    tele.MainButton.onClick(formik.handleSubmit);
    fetchRegions({
      setRegionOptions: setRegionOptions,
      setIsLoading: setIsLoading,
      setError: setError,
    });
    fetchRoles({
      setRoleOptions: setRoleOptions,
      setIsLoading: setIsLoading,
      setError: setError,
    });

    tele.ready();
  }, [tele]);

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang]);

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        var body = {
          first_name: values.first_name,
          father_name: values.father_name,
          grand_father_name: values.grand_father_name,
          phone_number: parsePhoneNumber(values.phone_number),
          telegram_phone_number: parsePhoneNumber(values.telegram_phone_number),
          sex: values.gender,
          kebele: values.kebele,
          role: values.role,
          created_by: user_id,
        };
        setIsLoading(true);
        var response;
        if (update_outbound == "true") {
          response = await updateDa({
            body: body,
            id: !isEmpty(fetchedProfileData.id) ? fetchedProfileData.id : "",
            setIsLoading: setIsLoading,
          });

          if (
            (response && response.status == 404) ||
            (response && response.response && response.response.status == 404)
          ) {
            response = await registerDa({
              body: body,
              setIsLoading: setIsLoading,
            });
          }
        }

        if (update_outbound != "true") {
          response = await registerDa({
            body: body,
            setIsLoading: setIsLoading,
          });
        }

        if (
          response.status == 201 ||
          response.status == 200 ||
          response.response.status == 201 ||
          response.response.status == 200
        ) {
          await verifyBotUser({
            id: user_id,
            status: "VERIFIED",
          });
          await tele.sendData(
            JSON.stringify({
              data: (response && response.data) || (response && response.response && response.response.data),
              status_code: 201,
            })
          );
          setIsLoading(false);
        } else {
          if (response?.status == 400) {
            setFormErrors(response?.data);
          }

          if (response?.response?.status == 400) {
            setFormErrors(response?.response?.data);
          }

          if (response?.status == 400 || response?.response?.status == 400) {
            toast.error(
              isUpdate
                ? "Error updating DA, please check the form try again."
                : "Error registering DA, please check the form try again.",
              {
                position: "top-center",
              }
            );
            setIsLoading(false);
            return;
          }
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        return error;
      }
    },
  });

  useEffect(() => {
    if (!isEmpty(formErrors)) {
      for (const [key, value] of Object.entries(formErrors)) {
        if (Array.isArray(value)) {
          const errorString = value.join(", ");
          formik.setFieldError(key, errorString);
        } else {
          formik.setFieldError(key, value);
        }
      }
    }
  }, [formErrors]);

  if (isLoading || isPopulatingProfile) return <CircularProgress />;
  return (
    <div>
      {isUpdate ? <h2>{t("da_update_form")}</h2> : <h2>{t("da_registration_form")}</h2>}
      <form onSubmit={formik.handleSubmit}>
        <TextField
          variant="outlined"
          fullWidth
          margin="dense"
          id="first_name"
          name="first_name"
          label={t("first_name")}
          value={formik.values.first_name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.first_name && Boolean(formik.errors.first_name)}
          helperText={formik.touched.first_name && formik.errors.first_name}
        />

        <TextField
          variant="outlined"
          fullWidth
          margin="dense"
          id="father_name"
          name="father_name"
          label={t("father_name")}
          value={formik.values.father_name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.father_name && Boolean(formik.errors.father_name)}
          helperText={formik.touched.father_name && formik.errors.father_name}
        />

        <TextField
          variant="outlined"
          fullWidth
          margin="dense"
          id="grand_father_name"
          name="grand_father_name"
          label={t("grand_father_name")}
          value={formik.values.grand_father_name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.grand_father_name && Boolean(formik.errors.grand_father_name)}
          helperText={formik.touched.grand_father_name && formik.errors.grand_father_name}
        />

        <TextField
          variant="outlined"
          fullWidth
          margin="dense"
          id="phone_number"
          name="phone_number"
          label={t("phone_number")}
          value={formik.values.phone_number}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.phone_number && Boolean(formik.errors.phone_number)}
          helperText={formik.touched.phone_number && formik.errors.phone_number}
        />

        <TextField
          variant="outlined"
          fullWidth
          margin="dense"
          id="telegram_phone_number"
          name="telegram_phone_number"
          label={t("telegram_phone_number")}
          value={formik.values.telegram_phone_number}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.telegram_phone_number && Boolean(formik.errors.telegram_phone_number)}
          helperText={formik.touched.telegram_phone_number && formik.errors.telegram_phone_number}
        />
        <TextField
          variant="outlined"
          fullWidth
          margin="dense"
          id="gender"
          name="gender"
          label={t("gender")}
          value={formik.values.gender}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.gender && Boolean(formik.errors.gender)}
          helperText={formik.touched.gender && formik.errors.gender}
          select>
          {genderOptions.map((option) => (
            <MenuItem
              key={option.value}
              value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          variant="outlined"
          fullWidth
          margin="dense"
          id="region"
          name="region"
          label={t("region")}
          value={formik.values.region}
          onChange={(e) => {
            fetchZones({
              selectedRegionId: e.target.value,
              setZoneOptions: setZoneOptions,
              setIsLoading: setIsLoading,
            });
            formik.setFieldValue("kebele", "");
            formik.setFieldValue("woreda", "");
            formik.setFieldValue("zone", "");
            formik.handleChange(e);
          }}
          onBlur={formik.handleBlur}
          error={formik.touched.region && Boolean(formik.errors.region)}
          helperText={formik.touched.region && formik.errors.region}
          select>
          {regionOptions.map((option) => (
            <MenuItem
              key={option.value}
              value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>

        <TextField
          variant="outlined"
          fullWidth
          margin="dense"
          id="zone"
          name="zone"
          label={t("zone")}
          value={formik.values.zone}
          disabled={formik.values.region.length < 1}
          onChange={(e) => {
            fetchWoredas({
              selectedZoneId: e.target.value,
              setWoredaOptions: setWoredaOptions,
              setIsLoading: setIsLoading,
            });
            formik.setFieldValue("kebele", "");
            formik.setFieldValue("woreda", "");
            formik.handleChange(e);
          }}
          onBlur={formik.handleBlur}
          error={formik.touched.zone && Boolean(formik.errors.zone)}
          helperText={formik.touched.zone && formik.errors.zone}
          select>
          {zoneOptions.map((option) => (
            <MenuItem
              key={option.value}
              value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>

        <TextField
          variant="outlined"
          fullWidth
          margin="dense"
          id="woreda"
          name="woreda"
          label={t("woreda")}
          value={formik.values.woreda}
          disabled={formik.values.zone.length < 1}
          onChange={(e) => {
            fetchKebeles({
              selectedWoredaId: e.target.value,
              setKebeleOptions: setKebeleOptions,
              setIsLoading: setIsLoading,
            });
            formik.setFieldValue("kebele", "");
            formik.handleChange(e);
          }}
          onBlur={formik.handleBlur}
          error={formik.touched.woreda && Boolean(formik.errors.woreda)}
          helperText={formik.touched.woreda && formik.errors.woreda}
          select>
          {woredaOptions.map((option) => (
            <MenuItem
              key={option.value}
              value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>

        <TextField
          variant="outlined"
          fullWidth
          margin="dense"
          id="kebele"
          name="kebele"
          label={t("kebele")}
          value={formik.values.kebele}
          disabled={formik.values.woreda.length < 1}
          onChange={(value) => {
            formik.setFieldValue("kebele", value.target.value);
          }}
          onBlur={formik.handleBlur}
          error={formik.touched.kebele && Boolean(formik.errors.kebele)}
          helperText={formik.touched.kebele && formik.errors.kebele}
          select>
          {kebeleOptions.map((option) => (
            <MenuItem
              key={option.value}
              value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          variant="outlined"
          fullWidth
          margin="dense"
          id="role"
          name="role"
          label={t("role")}
          value={formik.values.role}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.role && Boolean(formik.errors.role)}
          helperText={formik.touched.role && formik.errors.role}
          select>
          {roleOptions.map((option) => (
            <MenuItem
              key={option.value}
              value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        {/* 
        <Button
          margin='dense'
          color='primary'
          variant='contained'
          fullWidth
          style={{ marginTop: '10x' }}
          type='submit'>
          Submit
        </Button> */}
      </form>
    </div>
  );
};

export default RegisterDaForm;
